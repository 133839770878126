.headerFixed{
    position: fixed !important;
}

.headerNotFixed{
    position: relative;
}

.headerContent {
    font-family: 'Fira_Sans_Regular';
    font-size: 20px;
    align-items: center;
    height: 60px;
    background-color: #fff;
    border-bottom: 2px solid #5b6a75;
}

.headerContentPadding {
    padding: 0px 25px
}

.outerContainerMargin {
    margin: 0px 14% 0px 14%;
}

.width100vw {
    width: 100vw;
}

.backgroundWhite {
    background-color: #fff;
}

.justifyContentEnd {
    justify-content:flex-end;
}

.justifyContentStart {
    justify-content:flex-start;
}

.justifyContentCenter {
    justify-content: center;
}

.alignItemCenter {
    align-items: center
}

.alignItemStart {
    align-items: flex-start
}

.width100Percent {
    width: 100%
}

.width60Percent {
    width: 60%
}

.width40Percent {
    width: 40%
}

.width50Percent {
    width: 50%
}

.bannerImage {
    background-image: url(./../Image/background.jpg);
    background-size: cover;
}

.bannerHeight {
    height: 600px;
}

.marginTop62 {
    margin-top:62px;
}

.padding10Horizontal {
    padding : 0px 10px;
}

.padding10Vertical {
    padding: 10px 0px
}

.inlineBlock {
    display: inline-block;
}

.sectionHeight {
    height: 450px
}

.textAlignCenter {
    text-align: center;
}

.widthAuto {
    width: auto;
}

.margin15All {
    margin: 15px;
}
.margin0010{
    margin: 0px 0px 10px
}

.margin25Horizontal {
    margin: 0px 25px
}

.marginBottom20 {
    margin-bottom: 20px
}

.padding25Horizonal {
    padding: 0px 25px;
}

.padding10All {
    padding: 10px
}

.blueBackgroundColor {
    background-color: #f0f4ff
}

.grayBackgroundColor {
    background-color: #f9f9f9
}

.readMoreButton {
    color: #4e67eb;
    font-size: 18px;
    text-decoration-line: none;
}

.readMoreButton:hover {
    color: #4e67eb;
    font-size: 18px;
    text-decoration-line: none;
}

.readMoreLink {
    color: #4e67eb !important;
    font-size:16px ;
    text-decoration-line: none;
}

.headerLink {
    color: #5b6a75 !important;
    font-size: 20px;
    text-decoration-line: none;
}

.headerLink:hover {
    text-decoration-line: none;
    color: #4e67eb !important;
}

.headerBorder {
    border-bottom: 2px solid #4e67eb ;
}

.margin2035 {
    margin: 20px 35px 20px 20px;
}

.marginAbout {
    margin: 0px 15px 0px 39%
}

.marginService {
    margin: 0px 24% 0px 4%
}

.paddingService{
    padding: 0px 20px 20px 0px;
    align-items: flex-start
}

.headerPadding {
    padding: 1em 0px !important
}

.serviceLink {
    text-decoration: none;
    color: #5b6a75
}

.serviceLink:hover {
    text-decoration: none;
    color: #5b6a75
}
.logoStyle {
    width: 100px
}

/* @media screen and (max-device-width:640px), screen and (max-width:640px) {
    
} */

@media only screen and (max-width: 640px) {
    .hideMobile {
        display: none;
    }
    .widthAdjustment {
        width: 100%;
    }
    .margin2035 {
        margin: 10px 0px
    }
    .outerContainerMargin {
        margin: 10px;
    }
    .mobileColumn {
        display: flex;
        flex-direction: column !important;
    }
    .mobileRow {
        display: flex;
        flex-direction: row !important;
    }
    .width60Percent {
        width: 100%;
    }
    .width40Percent {
        width: 100%;
    }
    .marginAbout {
        margin: 0px
    }
    .marginService {
        margin: 0px;
    }
    .bannerHeight{
        height: auto;
    }
    .marginZero {
        margin: 15px 0px
    }
    .width50Percent {
        width: 100%;
    }
    .margin20 {
        margin: 20px
    }
    .padding20 {
        padding: 20px
    }
    .alignCenterMobile {
        text-align: center;
    }
    .paddingService{
        padding: 0px 0px;
        align-items: flex-start
    }
    .padding10All{
        padding: 0px;
    }
    .margin0{
        margin: 0px !important
    }

    .headerPadding{
        padding: 5px 10px !important;
    }

    .headerContentPadding {
        padding: 0px
    }
}


    
    